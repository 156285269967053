@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Volkhov&display=swap);
.auth ion-img{height:100vh;left:0;object-fit:cover;position:absolute;top:0;width:100%}.auth main{align-items:center;display:flex;height:100vh;justify-content:center;width:100%;position:relative;z-index:999}.auth main>div{background:rgba(255,255,255,0.85);padding:50px;text-align:center}

.addperson ion-grid{--ion-grid-padding: 0}.addperson ion-grid ion-col{padding:0}.addperson .dom-right{display:flex;justify-content:flex-end}

.drawer{background:white;bottom:0;height:100vh;max-width:500px;position:fixed;right:0;top:0;width:100%;-webkit-transition:-webkit-transform 300ms ease-in-out;transition:-webkit-transform 300ms ease-in-out;transition:transform 300ms ease-in-out;transition:transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;-webkit-transform:translate3d(100%, 0, 0);transform:translate3d(100%, 0, 0);z-index:999}.drawer.is-open{-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0)}.drawer-overlay{position:fixed;top:0;bottom:0;left:0;right:0;height:100vh;width:100%;opacity:1;background:rgba(0,0,0,0.5);z-index:998}

.roadblocks textarea{height:unset}.roadblocks ion-grid{--ion-grid-padding: 0;--ion-grid-column-padding: 0;width:100%}

.mentions{height:calc(100vh - 60px);overflow:auto}.mentions__login{align-items:center;display:flex;height:calc(100vh - 60px);justify-content:center}

.boardlane{background:white;box-shadow:none;border-right:1px solid #444;flex-shrink:0;height:100%;margin:0;width:100%;overflow-y:auto;opacity:0;-webkit-animation:fadeIn 0.3s ease-in-out forwards;animation:fadeIn 0.3s ease-in-out forwards}.boardlane ion-card-header{border-bottom:1px solid var(--ion-color-light);position:-webkit-sticky;position:sticky;top:0;z-index:9}.boardlane ion-card-header ion-item{--background: var(--ion-color-dark-tint)}.boardlane ion-card-header ion-item ion-input{--color: var(--ion-color-light)}.boardlane>ion-card-content{padding:0}.boardlane ion-grid{--ion-grid-padding: 0}.boardlane ion-grid ion-col{padding:0}.boardlane ion-fab-button{--box-shadow: none}.boardlane ion-range{--height: 24px;padding-top:8px}.boardlane ion-label{max-width:350px !important}.boardlane .dom-right{display:flex;justify-content:flex-end}@media screen and (min-width: 768px){.boardlane{max-width:calc(100% / 3.5)}}@-webkit-keyframes fadeIn{to{opacity:1}}@keyframes fadeIn{to{opacity:1}}

.taskcard{box-shadow:0 2px 6px rgba(0,0,0,0.15);border-radius:6px !important;border-top:var(--border-style);-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;width:100%}.taskcard ion-card-content{padding:0}.taskcard ion-card-content>div{font-size:14px;padding:12px 8px}.taskcard ion-card-content>ion-grid{--ion-grid-padding: 8px;border-top:1px solid #eee;font-size:12px}.taskcard ion-card-content ion-icon{font-size:18px}.taskcard ion-card-content ion-textarea{--padding-start: 8px !important;--background: #f5f5f5;margin-top:0}.taskcard ion-button{--padding-end: 4px;--padding-start: 4px;font-size:12px;margin:0}.taskcard .progressbar__wrapper{align-items:center;display:flex;padding:0 12px}.taskcard .progressbar__wrapper ion-range{padding:0}.taskcard:hover{box-shadow:0 4px 8px rgba(0,0,0,0.2)}

ion-content{--background: var(--ion-color-light)}.scrumboard{display:flex;height:calc(100vh - 55px);overflow-x:auto}.toolbar__withsearch{display:flex}

.departments .centered{display:flex;margin-top:10vh;justify-content:center;width:100%}.departments .centered ion-list{max-width:720px;width:100%}

.loader ion-img{height:100vh;left:0;object-fit:cover;position:absolute;top:0;width:100%}.loader main{align-items:center;display:flex;height:100vh;justify-content:center;width:100%;position:relative;z-index:999}.loader main>div{background:rgba(255,255,255,0.85);padding:50px;text-align:center}

.mainmenu .profile h2{font-family:var(--ion-heading-font-family)}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  /** warning **/
  --ion-color-warning: #ffcc00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  /** Fonts */
  --ion-font-family: 'Poppins', sans-serif;
  --ion-heading-font-family: 'Volkhov', serif;
}

ion-title, ion-list-header, ion-card-title, .alert-title {
  font-family: 'Volkhov', serif;
  font-family: var(--ion-heading-font-family);
}

ion-card {
  border-radius: 0px !important;
}

.u-clickable {
  cursor: pointer;
}

.u-link {
  color: #3880ff;
  color: var(--ion-color-primary);
  cursor: pointer;
  text-decoration: underline;
}

/* main {
  max-width: 992px;
  margin-left: auto;
  margin-right: auto;
} */

